<template>
  <b-card title="Ticket">

    <b-tabs>

      <b-tab @click="$router.push('/add-ticket')">
        <template #title>
          <feather-icon icon="PlusIcon" />
          <a href="">Add Ticket</a>
        </template>

   
      </b-tab>
      <b-tab @click="$router.push('/all-ticket')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <!-- <a  href="/all-categories">All Categories</a> -->
          <span>All Ticket</span>
        </template>
   
      </b-tab>

    </b-tabs>
 <router-view v-slot="{ Component, route }">
      <AddTicket @add-category="getData" v-if="route.name === 'add-ticket' " />
         <TicketList ref="allData" v-if="route.name === 'all-ticket' " />
      <router-view v-else>
        <component :is="Component" />
      </router-view>
    </router-view>
  </b-card>
</template>

<script>
import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

import AddTicket from './AddTicket.vue'
import TicketList from './TicketList'
export default {
  components: {

    BCard,
    BTabs,
    BTab,
TicketList,
    AddTicket,
  },
  setup() {
    const allData = ref()

    const getData = () => {

      allData.value.getevents()

    }
    return {
      getData,
      allData,

    }
  },
}
</script>
